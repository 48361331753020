import { useRef, useState, useEffect } from "preact/hooks";
import { ansiToHtml } from "../../utils/ansiToHtml.ts";
import JsonFetcher from "./JsonFetcher.tsx";

interface ServerRunRESTProps {
  selectedServerID: string | null;
}

export default function ServerRunREST({ selectedServerID }: ServerRunRESTProps) {
  const [testResults, setTestResults] = useState<string[]>([]);
  const [resultFilePath, setResultFilePath] = useState<string>(""); // State for result file path
  const [running, setRunning] = useState(false);
  const [jsonContent, setJsonContent] = useState<string | null>(null); // State for JSON content
  const resultsRef = useRef<HTMLPreElement>(null); 
  const MAX_LINES = 500;

  useEffect(() => {
    if (selectedServerID) {
      console.log("Selected Server ID for test run:", selectedServerID);
    }
  }, [selectedServerID]);

  const runTests = async () => {
    if (!running && selectedServerID) {
      setTestResults([]);
      setResultFilePath(""); // Clear result file path on new run
      setJsonContent(null); // Clear previous JSON content
      setRunning(true);
  
      try {
        // Fetch server, config, and token details
        console.log("Fetching server, config, and token...");
        
        const [serverRes, configRes, tokenRes] = await Promise.all([
          fetch(`/api/load-server?serverId=${selectedServerID}`),
          fetch(`/api/load-config?serverId=${selectedServerID}`),
          fetch(`/api/load-token?serverId=${selectedServerID}`),
        ]);
  
        if (!serverRes.ok || !configRes.ok || !tokenRes.ok) {
          throw new Error('Failed to load server, config or token.');
        }
  
        // Parse JSON responses
        const server = await serverRes.json();
        const config = await configRes.json();  
        const token = await tokenRes.json();  
  
        console.log("Server, config, and token fetched successfully.");
  
        // Start the tests
        console.log("Starting tests...");
        const response = await fetch("/api/run-denotests", {
          method: "POST",
          body: JSON.stringify({
            action: "start",
            configJson: config.config,
            token: token.token,
          }),
          headers: { "Content-Type": "application/json" },
        });
  
        if (!response.ok) {
          console.error("Failed to start tests:", await response.text());
          setRunning(false);
          return;
        }
  
        // Lese den resultFilePath aus dem Header der Antwort
        const resultFilePath = response.headers.get("X-Result-File");

        if (!resultFilePath) {
          throw new Error("Result file path not found in response headers.");
        }

        console.log("Result file path received:", resultFilePath);
        setResultFilePath(resultFilePath); // Speichere den resultFilePath

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let finalOutput = "";
  
        if (reader) {
          const readStream = async () => {
            while (true) {
              const { done, value } = await reader.read();
              if (done || !value) {
                setRunning(false);
                break;
              }
  
              const chunk = decoder.decode(value);
              finalOutput += chunk;
  
              const newLines = chunk.split("\n");
              setTestResults((prevResults) => {
                const allLines = [...prevResults, ...newLines];
                return allLines.slice(-MAX_LINES);
              });
  
              if (resultsRef.current) {
                setTimeout(() => {
                  resultsRef.current?.scrollTo(0, resultsRef.current.scrollHeight);
                }, 0);
              }
            }
          };
  
          await readStream();
        } else {
          console.error("No readable stream available.");
          setRunning(false);
        }
      } catch (error) {
        console.error("Error while starting tests:", error);
        setRunning(false);
      }
    }
  };
  
  // Function to load JSON content from result file
  const loadJsonFromFile = async (filePath: string) => {
    try {
      console.log("Fetching JSON content from file:", filePath);
      const response = await fetch(filePath); // Fetch the JSON file
      if (!response.ok) {
        throw new Error("Failed to load JSON file");
      }
      const data = await response.json();
      console.log("Fetched JSON content:", data);
      setJsonContent(JSON.stringify(data, null, 2)); // Pretty-print the JSON
    } catch (error) {
      console.error("Error loading JSON file:", error);
    }
  };

  const stopTests = async () => {
    if (running) {
      try {
        const response = await fetch("/api/run-denotests", {
          method: "POST",
          body: JSON.stringify({ action: "stop" }),
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          console.error("Failed to stop the tests:", await response.text());
        } else {
          setRunning(false);
        }
      } catch (error) {
        console.error("Error while stopping tests:", error);
      }
    }
  };

  return (
    <div>
      <button
        onClick={runTests}
        class="bg-blue-500 text-white px-4 py-2"
        disabled={running || !selectedServerID}
      >
        {running ? "Running Tests..." : "Run Tests"}
      </button>

      {running && (
        <button
          onClick={stopTests}
          class="bg-red-500 text-white px-4 py-2 ml-2"
        >
          Stop
        </button>
      )}

      <div class="mt-4">
        <h2 class="text-lg font-bold">Test Results</h2>
        <pre
          ref={resultsRef}
          class="p-4 bg-black text-white border rounded h-96 overflow-auto font-mono whitespace-pre-wrap resize-y"
        >
          {testResults.map((line, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: ansiToHtml(line) }} />
          ))}
        </pre>
      </div>

      {/* Display the result file path and its content */}
      {!running && resultFilePath && (
        <div class="mt-4">
        {/* Display the JSON content if available */}
      <JsonFetcher resultFilePath={resultFilePath} />  
        </div>
      )}

     
    </div>
  );
}
